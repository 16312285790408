<template>
    <section>
       <div class="content-wrapper">
           <div class="row">

               <h1>Mes Achats</h1>
               <div class="col-md-12">
                   <div v-if="isload" class="jumping-dots-loader">
                        <span></span>
                        <span></span>
                        <span></span>
                </div>
                   <b-table 
                    :items="items" 
                    id="table-list" 
                    responsive :per-page="perPage" 
                    :current-page="currentPage" 
                    :fields="fields" 
                    :sort-by.sync="sortBy" 
                    :sort-desc.sync="sortDesc"
                    :filter="search"
                    selectable
                    @row-clicked="details"
                    >
                  <template #cell(client)="row">
                    <span v-if="row.item.deliveryinfos">
                        <span class="pointer" @click="ordersDetails(row.item.ulid)" v-if ="row.item.deliveryinfos.name">{{row.item.deliveryinfos.name}}</span>
                        <span v-else> Pas de nom </span>
                    </span>
                    <span v-else>
                        Pas d'information de livraison dans les données
                    </span>
                  </template>
                  <template #cell(type)="row">
                    <span v-if="row.item.purchases[0]">
                      <span class="badge badge-outline-default badge-pill" v-if="row.item.purchases[0].product"><i class=" mdi mdi-cart mr-2"></i>Achat</span>
                      <span class="badge badge-outline-default badge-pill" v-else-if="row.item.purchases[0].service"><i class="mdi mdi-calendar mr-2"></i>Réservation</span>
                      <span class="badge badge-outline-default badge-pill" v-else-if="row.item.purchases[0].voucher"><i class="mdi mdi-calendar mr-2"></i>Carte cadeau</span>
                      <span v-else>Vide</span>
                  </span>
                  </template>

                  <template #cell(date)="row">
                    <span v-if="row.item">{{row.item.purchases[0].datepurchase.substring(0,11)}}</span>
                  </template>

                  <template #cell(status)="row">
                    <span v-if="row.item.status !== 'cancelled'">
                        <span class="badge badge-primary" v-if="row.item.status==='Préparation'">Préparation</span>
                        <span class="badge badge-success" v-else-if="row.item.status==='Livraison'">Livraison</span>
                    </span>
                    <span class="badge badge-danger" v-else>Annulé</span>
                  </template>

                </b-table>
                <h3 v-if="items.length<1">Vous n'avez pas d'achat</h3>
                <b-pagination
                v-else
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="table-list"
                  align="right">
                </b-pagination>
               </div>
           </div>
       </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name:"myorders",
    data(){
        return{
            perPage: 10,
            isload:true,
            sortBy: 'name',
            currentPage: 1,
            sortDesc: false,
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true,
            search:'',
            fields: [
            { key: 'client', label:'Bénéficiaire', sortable: true },
            { key: 'type', sortable: true },
            { key: 'date', sortable: true },
            { key: 'status', sortable: true },
            // { key: '#' }
            ],
            items: [
            ]
        }
    },
    computed: {
        rows() {
        return this.items.length
      }
    },
    methods: {
        details(record){
            this.ordersDetails(record.ulid)
        },
        getMyOrders () {
            this.isload = true
            axios.get('myorders')
            .then(async resGetMyOrders => {
                let res = resGetMyOrders.data.result
                let respurchases = await res.filter(i => i.purchases.length>0 )
                this.items = respurchases.filter(i => !i.purchases[0].service)
                this.isload = false
            })
            .catch(errGetMyOrders => {
                this.isload = false
                console.log({errGetMyOrders})})
        },
        ordersDetails(ulid) {
            this.$router.push({
            name:"ordersDetails",
            params: {
                ulid: ulid,
                type:"achat"
            }
            })
        },
        removeOrder(order){
            this.$swal({
                title: 'Désirez-vous annuler votre réservation?',
                showCancelButton: true,
                confirmButtonColor: '#0b0b0b',
                cancelButtonColor: '#fff',
                confirmButtonText: 'Valider',
                cancelButtonText: 'Annuler'
            })
            .then((result) => {
                if (result.value) {
                this.isload = true
                axios.delete('myorders/'+order.item.ulid)
                    .then(()=>{
                    this.getMyOrders()
                    })
                    .catch()
                }else{
                    this.isload=false
                }
            })
        }
    },
    mounted () {
        this.getMyOrders()
    },
    beforeCreate() {
        // mettre X-Auth-token sur toutes les requêtes de la page
        axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('token');
    }
}
</script>